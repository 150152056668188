<template>
  <div v-if="ready" :key="appKey" id="app">
    <router-view :key="appKey" />
  </div>
</template>

<script>
import api from './utils/api'

export default {
  name: 'app',
  data() {
    return {
      ready: false,
      clearCacheToastId: undefined
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    appKey() {
      return this.$store.state.appKey
    },
    toasts() {
      return this.$store.state.toasts
    }
  },
  watch: {
    store: {
      handler() {
        this.updateNavigation()
      }
    },
    toasts: {
      handler(v) {
        if (v.length) {
          for (const [text, type] of v) {
            this.toast(text, type)
          }
          this.$store.commit('clearToasts')
        }
      },
      deep: true
    },
    cacheData: {
      handler(v) {
        this.$toast.dismiss(this.clearCacheToastId)
        if (v) {
          const id = this.$toast(
            v.byEndpoint
              ? this.localize('Нажмите, чтобы очистить кеш для точки')
              : this.localize('Нажмите, чтобы очистить кеш'),
            {
              position: 'bottom-left',
              icon: false,
              timeout: 0,
              style: {
                cursor: 'pointer',
                backgroundColor: '#d66800',
                fontWeight: 'bold'
              },
              onClick: () => {
                if (!this.isLoading) {
                  this.$toast.dismiss(id)
                  this.clearCache()
                }
              }
            }
          )
          this.clearCacheToastId = id
        }
      }
    }
  },
  created() {
    api.bindTo(this)
    this.$store.commit('cacheMap', localStorage.getItem('cacheMap'))
    this.auth()
  },
  methods: {
    async auth() {
      try {
        await this.$store.dispatch('Auth')
      } catch {}
      if (!this.user.isLoggedIn) {
        this.$store.dispatch('login')
      }
			else if (!this.user.hasMappingKC){
				const m = 'Неверно настроен клиент в KeyCloak. \nОбратитесь к администратору. \nАвторизоваться в другой учетной записи?'
	      if (confirm(this.localize(m))) {
		      this.$store.dispatch('Logout')
	      }
      }
			else if (!this.user.access) {
				const m = 'У пользователя отсутствует доступ к данному приложению. \nОбратитесь к администратору. \nАвторизоваться в другой учетной записи?'
        if (confirm(this.localize(m))) {
          this.$store.dispatch('Logout')
        }
      } else if (this.user.access) {
        this.ready = true
        this.updateNavigation()
        this.$store.dispatch('GetLicense')
      }
    },
    async updateNavigation() {
      const v = await api.post('navigation', {
        ...this.$store.state.systems,
        ...this.$route.params
      })
      this.$store.commit(
        'Navigation',
        v.map(e => `${e.label} (${this.localize(e.type)})`).join(' / ')
      )
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/main.scss';

html {
  font-size: $font-size;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $color-red-dark;
  @include transition();

  &:hover {
    color: $color-red-bright;
  }
}

p {
  margin: 0;
}

* {
  scrollbar-color: $color-scroll transparent;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: auto;

  &::-webkit-scrollbar {
    width: rem(8px);
  }

  &::-webkit-scrollbar-thumb {
    border-right: 4px solid rgba(0, 0, 0, 0);
    background-clip: content-box;
    background-color: $color-scroll;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

table {
  box-sizing: border-box;
  padding: 20px;
  td {
    padding: 7px;
    vertical-align: middle;
    &:not(.non-break) {
      word-break: break-word;
    }
    &.non-break {
      padding: 3px;
      white-space: nowrap;
      button {
        font-size: 10px;
        padding: 4px 13px;
        line-height: 15px;
      }
    }
  }
  thead {
    font-weight: 500;
    font-size: 15px;
    td {
      padding-left: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }
  tbody {
    tr {
      &:not(:first-child) {
        td {
          border-top: 1px dotted rgba(0, 0, 0, 0.15);
        }
      }
      &:nth-child(odd) {
        $v: 242;
        td {
          background: rgb($v, $v, 255);
        }
      }
	    td {
		    background: white;
	    }
      .button--stroke {
        background: white;
      }
    }
  }
}

h1 {
  margin-left: 25px;
  margin-top: 15px;
  font-weight: 500;
}

.split {
  &-wrapper {
    display: flex;
  }
  &-item:first-child {
    flex-grow: 1;
  }
  &-item:last-child {
    flex-grow: 1.5;
  }
}

.required .input__label::after {
  content: '*';
}

.import {
  display: inline-block;
  margin-left: 20px;
  input {
    position: absolute;
    left: -1000px;
  }
  label {
    cursor: pointer;
  }
}

.return {
  transform: rotate(90deg);
  cursor: pointer;
  svg {
    fill: white;
  }
}

.split-modal {
  .modal__container {
    width: 88rem;
    //max-height: 58rem;
    //transform: scale(0.9);
    .split {
      display: flex;
      .split-block {
        flex-grow: 1;
        &:first-child {
          padding-right: 10px;
        }
        &:last-child {
          padding-left: 10px;
        }
        &:not(:first-child) {
          &:not(:last-child) {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>
